import colors from './variables.scss';

const styles = {
  ...colors,
  MENU_HEADER_HEIGHT: 71,
  MENU_MIN_HEIGHT: 71,
  PROFILE_IMAGE: require('../assets/profile-image.png'),
};

export default styles;
